import React from "react";

export default function Who (){
    return(
        <section>
            <div className="answer">
                <p className="ans"> We’re a UK-based tech network for women by women. We aim to empower and connect women across the UK to become the next generation of leaders in computing.</p>
            </div>
        </section>


    )
}