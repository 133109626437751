export const members = [
    {
        image:'/images/aurelia.jpg',
        name: 'Aurelia Brzezowska',
        role: 'CEO',
        instagram: 'https://www.instagram.com/kasia.aur',
        linkedin: 'https://www.linkedin.com/in/aurelia-brzezowska'
    },
    {
        image:'/images/ilenia.jpg',
        name: 'Ilenia Maietta',
        role: 'Content Manager & Mentor',
        instagram: 'https://www.instagram.com/ilenia.digital',
        linkedin: 'https://www.linkedin.com/in/ilenia-maietta'
    },
    {
        image:'/images/manpreet.png',
        name: 'Manpreet Kaur',
        role: 'Web Developer',
        instagram: 'https://www.instagram.com/randhawa._.manpreet',
        linkedin: 'https://www.linkedin.com/in/manpreet-kaur-dev'
        
    },
    {
        image:'/images/nadira.jpg',
        name: 'Nadira Alom',
        role: 'Social Media Manager',
        instagram: 'https://www.instagram.com/nadira_alom',
        linkedin:'https://www.linkedin.com/in/nadira-alom-841417246'
    },
    {
        image:'/images/longHairFringe.png',
        name: 'Saloni Desai',
        role: 'Social Media Manager',
        instagram: 'https://www.instagram.com/salonidreams',
        linkedin:'https://www.linkedin.com/in/saloni-desai-5841061b7'
    },
    {
        image:'/images/longHairFringe.png',
        name: 'Samanta Hajee',
        role: 'Social Media Manager',
        instagram: 'https://www.instagram.com/19.samantaa',
        linkedin:'https://www.linkedin.com/in/samanta-hajee'
    },

]
