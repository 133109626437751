import React, { useContext} from "react";
import { members } from "../data/teamData";
import { UserContext } from "../context/userContext";

export default function OurTeam() {
    const { handleTouch } = useContext(UserContext);

    // Grouping members into CEO, Web Dev Team, and Social Media Team
    const ceo = members.find((el) => el.role === "CEO");
    const webDevTeam = members.filter((el) => el.role.includes("Web Developer"));
    const socialMediaTeam = members.filter((el) => el.role.includes("Social Media Manager")|| el.role.includes("Content Manager & Mentor"));
     
    return (
        <section className="aboutTeam" onClick={handleTouch}>
            <div className="teamContainer">

                {/* CEO */}
                <div className="teamGroup ceo">
                    <div className="ceoAurelia">
                        <span>CEO</span>
                    </div>
                    <div className="members">
                        <div className="icon">
                            <img src={ceo.image} alt={ceo.name} className="icon userImage" /> {/* Display image */}
                        </div>
                        <div className="name">{ceo.name}</div>
                        <div className="role">{ceo.role}</div>
                        <div className="socialMedia">
                            <a href={ceo.instagram} className="ig">
                                <span className="visually-hidden">Instagram</span>
                            </a>
                            <a href={ceo.linkedin} className="linked">
                                <span className="visually-hidden">LinkedIn</span>
                            </a>
                        </div>
                    </div>
                </div>

                {/* Team Title */}
                <div className="teamTitle">
                    <div className="webDevTeam">
                        <span>Web Developers</span>
                    </div>
                    <div className="socialMediaTeam">
                        <span>Social Media Managers</span>
                    </div>
                   
                </div>


                
                <div className="companyTeams">
                    {/* Web Development Team */}
                    <div className="teamGroup webDev">
                        {webDevTeam.map((el, index) => (
                            <div className="devMembers" key={index}>
                                <div className="icon">
                                    <img src={el.image} alt={el.name} className="icon userImage" /> {/* Display image */}
                                </div>
                                <div className="name">{el.name}</div>
                                <div className="role">{el.role}</div>
                                <div className="socialMedia">
                                    <a href={el.instagram} className="ig">
                                        <span className="visually-hidden">Instagram</span>
                                    </a>
                                    <a href={el.linkedin} className="linked">
                                        <span className="visually-hidden">LinkedIn</span>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Social Media Team */}
                    <div className="teamGroup socialMediaManagers">
                        {socialMediaTeam.map((el, index) => (
                            <div className="socialMembers" key={index}>
                                <div className="icon">
                                    <img src={el.image} alt={el.name} className="icon userImage" /> {/* Display image */}
                                </div>
                                <div className="name">{el.name}</div>
                                <div className="mediaRole">{el.role}</div>
                                <div className="socialMedia">
                                    <a href={el.instagram} className="ig">
                                        <span className="visually-hidden">Instagram</span>
                                    </a>
                                    <a href={el.linkedin} className="linked">
                                        <span className="visually-hidden">LinkedIn</span>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
