import React from "react";

export default function Why (){
    return(
        <section>
            <div className="answer">
                <p className="ans"> We believe that a sense of community can give you a voice. We want to give the underrepresented a chance to pave their way into tech and bridge the gender gap once and for all. Will you join us?</p>
            </div>
        </section>


    )
}